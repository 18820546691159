<template>
    <section class="container">
        <div class="section-container">
            <div class="button-wrapper">
                {{ hexColour }}
                <v-btn
                    type="button"
                    depressed
                    @click="addNew()"
                    class="accent"
                >
                    <v-icon small>add</v-icon>
                    Add Locations
                </v-btn>
            </div>

            <map-location-card
                v-for="location in locationsArray"
                :key="location.locationID"
                :data="location"
                :category_id="category_id"
                @deleteLocation="deleteLocation(location.id)"
            />

            <div
                v-if="locationsArray < 1"
                class="map-icon-container"
            >
                <img
                    src="../../../assets/map-directory/MapUI.svg"
                    alt=""
                />
                <p>0 Locations Exist</p>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";
import MapLocationCard from "../../../components/MapDirectory/Locations/MapLocationCard.vue";

export default {
    name: "MapCategoriesList",

    components: {
        MapLocationCard,
    },

    data: () => ({
        api: process.env.VUE_APP_MAPDIRECTORYAPI,
        locationsArray: null,
        hexColour: null,
    }),

    mounted() {
        this.fetchCategoriesArray();
    },

    methods: {
        addNew() {
            this.categoryId = this.$route.params.category_id;

            this.$router.push({
                path: `/map-directory/categories/${this.categoryId}/locations-list/create`,
            });
        },

        fetchCategoriesArray() {
            this.$store.commit("startLoading");
            this.category_id = this.$route.params.category_id;
            console.log("categories array params", this.category_id);
            this.$getCurrentUserJwToken().subscribe((jwt) => {
                axios({
                    method: "GET",
                    url: `${this.api}/categories/${this.category_id}`,
                    headers: { Authorization: jwt },
                })
                    .then((response) => {
                        if (response.data.data.relationships)
                            this.locationsArray = response.data.data.relationships.locations;
                        console.log(
                            "Get single category LOCATION LIST",
                            response.data.data
                        );

                        this.$store.commit("completeLoading");
                    })
                    .catch((e) => {
                        console.error("Problem receiving location data", e);
                        this.$store.commit("completeLoading");
                    });
            });
        },

        deleteLocation(id) {
            if (
                confirm(
                    "Are you sure you want to delete this Location? This is irreversible."
                )
            ) {
                this.$store.commit("startLoading");
                this.$getCurrentUserJwToken().subscribe((jwt) => {
                    axios({
                        method: "DELETE",
                        url: `${this.api}/locations/${id}`,
                        headers: { Authorization: jwt },
                    })
                        .then((response) => {
                            this.fetchCategoriesArray();
                            this.$store.commit("completeLoading");
                        })
                        .catch((e) => {
                            console.error("Problem deleting location", e);
                            this.$store.commit("completeLoading");
                        });
                });
            }
        },
    },
};
</script>

<style scoped lang='scss'>
.button-wrapper {
    display: flex;
    flex-direction: row;
}

.filters {
    max-width: 250px !important;
}

.list-complete-enter,
.list-complete-leave-to {
    opacity: 0;
}

.list-complete-leave-active {
    position: absolute;
    width: calc(100% - (var(--body-gutter) * 2));
}

.map-icon {
    &-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}
</style>
